import React from 'react'
import { Text, Container } from 'components'
import { ForgotPasswordForm } from 'compositions/ResetPasswordForm'

export const ForgotPassword: React.FC = () => {
  return <Container maxWidth="sm">
    <Text variant="h4">Forgot Password</Text>
    <ForgotPasswordForm />
  </Container>
}

export default ForgotPassword
