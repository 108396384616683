import React from 'react'
import { Text, Container } from 'components'
import { LoginForm } from 'compositions/LoginForm'

export const Login: React.FC = () => {
  return <Container maxWidth="sm">
    <Text variant="h4">Login</Text>
    <LoginForm />
  </Container>
}

export default Login
