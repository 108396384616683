import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useTodosWizard } from 'hooks'
import {
  // Home as HomeIcon,
  Catalog as CatalogIcon,
  Orders as OrdersIcon,
  Menu as MenuIcon,
  Settings as SettingsIcon,
  Assignment as AssignmentIcon,
  ManageAccount as ManageAccountIcon,
} from 'icons'
import { Routes, RouteDefinitions, Redirect } from 'compositions/Routes'
import {
  useCurrentVendorLocation,
  useKitchenTodos
} from '@kitchen/hooks'
import { KitchenTodosBadge } from '@kitchen/components/Todos'
import { Login } from './Login'
import { ResetPassword, ForgotPassword } from './ResetPassword'
// import { Home } from './Home'
import { Products } from './Products'
import { Menus } from './Menus'
import { Fulfillment } from './Fulfillment'
import { Settings, Documents, UploadDocument, Profile } from './Settings'
import { Todos } from './Todos'

export const routes: RouteDefinitions = [
  // { path: '/', element: <Home />, name: 'Dashboard', icon: HomeIcon, navMenu: true },
  { path: '/', element: <Fulfillment />, name: 'Orders', icon: OrdersIcon, navMenu: true },
  { path: 'products', element: <Products />, name: 'Catalog', icon: CatalogIcon, navMenu: true, role: 'ADMIN' },
  { path: 'menus', element: <Menus />, name: 'Menus', icon: MenuIcon, navMenu: true, level: 50 },
  { path: 'settings', element: <Redirect to="/settings/kitchen" options={{ replace: true }} />, name: 'Settings', icon: SettingsIcon, navMenu: true, role: 'ADMIN', pathMatch: 'settings' },
  { path: 'settings/kitchen', element: <Settings />, name: 'Kitchen', navMenu: true, role: 'ADMIN', parent: 'Settings' },
  { path: 'settings/documents', element: <Documents />, name: 'Documents', navMenu: true, role: 'ADMIN', parent: 'Settings' },
  { path: 'settings/documents/upload', element: <UploadDocument />, name: 'Upload Document', navMenu: false, role: 'ADMIN', parent: 'Settings' },
  { path: 'todos/*', element: <Todos />, name: 'Todos', icon: AssignmentIcon, navMenu: true, badgeComponent: KitchenTodosBadge },
  { path: 'profile', element: <Profile />, name: 'Manage Account', icon: ManageAccountIcon, navMenu: false },
  { path: 'login', element: <Login />, name: 'Login', public: true },
  { path: 'users/password/new', element: <ForgotPassword />, name: 'Forgot Password', public: true, navMenu: false },
  { path: 'users/password/edit', element: <ResetPassword />, name: 'Reset Password', public: true, navMenu: false },
  { path: '*', element: <Login />, name: 'Login', public: true },
]

export const Router: React.FC = () => {
  const location = useLocation()
  const { vendorLocation } = useCurrentVendorLocation()
  const { data: { kitchenTodos }, refetch } = useKitchenTodos({ location: vendorLocation })
  const { navigateToBlocking, blocked } = useTodosWizard({ todos: kitchenTodos.rows, refetch })

  useEffect(() => {
    navigateToBlocking(location.pathname)
  }, [ location, navigateToBlocking ])

  if (blocked(location.pathname)) {
    return null
  } else {
    return <Routes appName="Kitchen Manager" routes={routes} location={vendorLocation} />
  }
}

export default Router
