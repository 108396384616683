import React from 'react'
import { Text, Container } from 'components'
import { ResetPasswordForm } from 'compositions/ResetPasswordForm'

export const ResetPassword: React.FC = () => {
  return <Container maxWidth="sm">
    <Text variant="h4">Reset Password</Text>
    <ResetPasswordForm />
  </Container>
}

export default ResetPassword
